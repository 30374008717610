import styled from "@emotion/styled"
import React from "react"
import colors from "../styles/colors"
import { Tooltip } from "./tooltip"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const RangeSlider = styled.div`
  top: -10px;
  position: absolute;
  width: 100%;
`

const IndicatorContainer = styled(Tooltip)`
  position: absolute;
  top: -48%;
  left: ${p => p.value}%;
`

const INDICATOR_WIDTH = 60
const Indicator = styled.div`
  height: 40px;
  width: ${INDICATOR_WIDTH}px;
  border-radius: 1000px;
  border: 2px solid ${colors.primary};
  background-color: ${colors.white};
`

const InputRangeSlider = styled.input`
  opacity: 0;
  visibility: hidden;
  top: 0;
`

const Track = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    ${colors.primary} 0%,
    ${colors.primary} ${p => p.value}%,
    ${colors.grey} ${p => p.value}%,
    ${colors.grey} 100%
  );
`

const MinMax = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${colors.grey};
  margin-bottom: 0.5rem;
`
const MinLabel = styled.span``
const MaxLabel = styled.span``

const trackFinger = (event, touchId) => {
  if (touchId.current !== undefined && event.changedTouches) {
    for (let i = 0; i < event.changedTouches.length; i += 1) {
      const touch = event.changedTouches[i]
      if (touch.identifier === touchId.current) {
        return {
          x: touch.clientX,
          y: touch.clientY,
        }
      }
    }

    return false
  }

  return {
    x: event.clientX,
    y: event.clientY,
  }
}

export const Slider = ({
  defaultValue = 0,
  min = 0,
  max = 100,
  valuePrefix = "",
  divider = 1,
  onChange = () => {},
  ...props
}) => {
  const [value, setValue] = React.useState(defaultValue / divider)
  const [minMax, setMinMax] = React.useState({ min: 0, max: 0 })
  const [dragStopped, setDragStopped] = React.useState(false)
  const [sliderWidth, setSliderWidth] = React.useState(0)
  const touchId = React.useRef()
  const sliderRef = React.useRef()

  React.useEffect(() => {
    windowResize()
    window.addEventListener("resize", windowResize)

    return () => window.removeEventListener("resize", windowResize)
  }, [])

  React.useEffect(() => {
    if (dragStopped) {
      onChange(value * divider)
      if (window.navigator.vibrate) window.navigator.vibrate(10)
    }
  }, [divider, dragStopped, onChange, value])

  const handleDragMove = React.useCallback(
    e => {
      // Avoid text selection
      e.preventDefault()

      const { x } = trackFinger(e, touchId)

      const relativeValue = ((x - minMax.min) / (minMax.max - minMax.min)) * 100
      const val = (relativeValue * (max - min)) / 100 + min

      let roundedValue = Math.min(max, Math.max(Math.round(val), min))
      if (val > max) roundedValue = max
      if (val < min) roundedValue = min

      setValue(roundedValue)
    },
    [max, min, minMax.max, minMax.min]
  )

  // TODO: See if there is a better way to refactor dependencies between handleTouchEnd and stopListening
  const handleTouchEnd = React.useRef()

  const stopListening = React.useCallback(() => {
    document.removeEventListener("mousemove", handleDragMove)
    document.removeEventListener("mouseup", stopListening)
    sliderRef.current.removeEventListener("touchmove", handleDragMove)
    sliderRef.current.removeEventListener("touchend", handleTouchEnd.current)
    touchId.current = undefined
    setDragStopped(true)
  }, [handleDragMove])

  React.useEffect(() => {
    handleTouchEnd.current = () => {
      touchId.current = undefined
      stopListening()
    }
  }, [stopListening])

  const handleTouchStart = React.useCallback(
    e => {
      const touch = e.changedTouches[0]
      if (touch !== null) {
        touchId.current = touch.identifier
      }

      sliderRef.current.addEventListener("touchmove", handleDragMove)
      sliderRef.current.addEventListener("touchend", handleTouchEnd.current)
    },
    [handleDragMove]
  )

  React.useEffect(() => {
    const { current: slider } = sliderRef
    slider.addEventListener("touchstart", handleTouchStart)

    return () => {
      slider.removeEventListener("touchstart", handleTouchStart)
      stopListening()
    }
  }, [handleTouchStart, stopListening])

  const windowResize = () => {
    const {
      x: parentStart,
      width: parentWidht,
    } = sliderRef.current.getBoundingClientRect()
    setSliderWidth(parentWidht)
    const parentStop = parentStart + parentWidht
    setMinMax({ min: parentStart, max: parentStop })
  }

  const startTrackingMouse = () => {
    document.addEventListener("mousemove", handleDragMove)
    document.addEventListener("mouseup", stopListening)
  }

  const relativeValue = ((value - min) * 100) / (max - min)

  return (
    <Wrapper ref={sliderRef} {...props}>
      <MinMax>
        <MinLabel>{min}</MinLabel>
        <MaxLabel>
          {max}
          {valuePrefix}
        </MaxLabel>
      </MinMax>
      <Track value={relativeValue}>
        <RangeSlider style={{ width: `${sliderWidth - INDICATOR_WIDTH}px` }}>
          <IndicatorContainer
            direction="ABOVE"
            alwaysActive={true}
            text={`${value}${valuePrefix}`}
            value={relativeValue}
            style={{ fontSize: "20px", fontWeight: 600 }}
            bgColor={colors.primary}
            noMinWidth
          >
            <Indicator
              onMouseDown={e => {
                startTrackingMouse()
              }}
            />
          </IndicatorContainer>
          <InputRangeSlider
            type="range"
            value={value}
            onChange={e => {
              setValue(e.target.value)
            }}
          />
        </RangeSlider>
      </Track>
    </Wrapper>
  )
}
