import React from "react"
import styled from "@emotion/styled"

import Typography from "../../components/typography"
import colors from "../../styles/colors"
import { useRoiContext } from "../../contexts/roiContext"
import Global from "../../styles/global"
import Header from "../../components/header"
import Card from "../../components/card"
import { Select } from "../../components/select"
import { BigPillButton } from "../../components/button"
import Footer from "../../components/footer"
import { Slider } from "../../components/slider"
import CookieBanner from "../../components/cookieBanner"
import Link from "../../components/link"
import { AverageSalaryWrapper } from "../../components/roi/roiCalculator"
import { SalaryInput } from "../../components/roi/salaryInput"

const ContentWrapper = styled.div`
  margin-top: 80px;
`

const Text = styled(Typography)`
  color: ${colors.darkgrey};
`
const CardTitle = styled(Typography)`
  color: ${colors.darkgrey};
  font-weight: 700;
  @media screen and (min-width: 769px) {
    font-size: 60px;
    line-height: 65px;
    margin-bottom: 3rem;
  }
`

const InputSlider = styled(Slider)`
  margin-top: 5rem;
`

const ProductCategorySelect = styled(Select)`
  margin-top: 2rem;
  width: 100%;
  @media screen and (min-width: 769px) {
    margin-top: 4rem;
    max-width: 300px;
  }
`

const CardMaxWidth = styled(Card)`
  max-width: 855px;
  @media screen and (min-width: 769px) {
    text-align: center;
    align-items: center;
  }
`

const RoiInputPage = ({ location }) => {
  const {
    stepDone,
    supportRequests,
    serviceAgents,
    currency,
    averageSalary,
    productCategory,
    setSupportRequests,
    setServiceAgents,
    setCurrency,
    setAverageSalary,
    setProductCategory,
  } = useRoiContext()

  return (
    <>
      <Global bg="white" />
      <Header bg="white" activeTab="learn" />
      <ContentWrapper>
        <CardMaxWidth variant="lightgrey">
          <CardTitle variant="h2">
            How many support requests do you have every month?
          </CardTitle>
          <Text variant="bodyLarge" style={{ marginTop: "2rem" }}>
            Consider requests from all channels: emails, phone calls, chat etc.
          </Text>
          <InputSlider
            defaultValue={supportRequests}
            onChange={setSupportRequests}
            min={0}
            max={1200}
            valuePrefix="k"
            divider={1000}
          />
        </CardMaxWidth>
        <CardMaxWidth variant="lightgrey">
          <CardTitle variant="h2">
            How many service agents do you have?
          </CardTitle>
          <Text variant="bodyLarge" style={{ marginTop: "2rem" }}>
            Consider full-time equivalents. For example, two part-time agents
            equals one full-time agent, and so on.
          </Text>
          <InputSlider
            defaultValue={serviceAgents}
            onChange={setServiceAgents}
            min={0}
            max={5000}
          />
        </CardMaxWidth>
        <CardMaxWidth variant="lightgrey">
          <CardTitle variant="h2">
            What’s the average monthly salary of your service agents?
          </CardTitle>
          <Text
            variant="bodyLarge"
            style={{ marginTop: "2rem", marginBottom: "3rem" }}
          >
            Including taxes, insurance, benefits, etc. (fully loaded cost).
          </Text>
          <AverageSalaryWrapper>
            <Select
              style={{
                marginRight: "1rem",
              }}
              defaultValue={currency}
              onChange={e => setCurrency(e.target.value)}
            >
              <option value="eur">€ EUR</option>
              <option value="usd">$ USD</option>
            </Select>
            <SalaryInput
              onChange={e => setAverageSalary(e.target.value)}
              variant="white"
              focusBorderVariant="primary"
              value={averageSalary}
              type="number"
            />
          </AverageSalaryWrapper>
        </CardMaxWidth>
        <CardMaxWidth variant="lightgrey">
          <CardTitle variant="h2">What’s your product?</CardTitle>
          <Text variant="bodyLarge" style={{ marginTop: "2rem" }}>
            Select the type/types of product you manufacture and provide support
            for.
          </Text>
          <ProductCategorySelect
            onChange={e => setProductCategory(e.target.value)}
            defaultValue={productCategory}
            style={{ height: "40px" }}
          >
            <option disabled selected>
              Product categories
            </option>
            <option value="consumer-electronics">Consumer electronics</option>
            <option value="household-applicances">Household appliances</option>
            <option value="gardening-equipment">Gardening equipment</option>
            <option value="industrial-equipments">Industrial equipments</option>
            <option value="software">Software</option>
            <option value="vehicle">Vehicle</option>
            <option value="other">Other</option>
          </ProductCategorySelect>
        </CardMaxWidth>
        <CardMaxWidth center variant="lightgrey" noMinHeight>
          <BigPillButton
            to="/roi/details"
            as={Link}
            onClick={() => {
              stepDone("ROI_INPUTS", {
                supportRequests,
                serviceAgents,
                currency,
                averageSalary,
                productCategory,
              })
            }}
            variant="primary"
            style={{ height: "90px" }}
          >
            Calculate my ROI
          </BigPillButton>
        </CardMaxWidth>
        <Footer />
      </ContentWrapper>
      <CookieBanner />
    </>
  )
}

export default RoiInputPage
